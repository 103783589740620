
.overlay {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;

    max-width: 1440px;
    height: auto;
    
    @include for-desktop{
        background-image: url(/src/images/hero-section-desktop.jpg);
        padding-top: 180px;
        padding-bottom: 180px;
    }
    @include for-laptop-only {
        background-image: url(/src/images/hero-section-desktop.jpg);
        padding-top: 180px;
        padding-bottom: 180px;
    }
    @include for-tablet-only {
        background-image: url(/src/images/hero-section-tab.jpg);
        padding-top: 329px;
        padding-bottom: 382px;
    }
    @include for-mobile-only {
        background-image: url(/src/images/hero-section-mob.jpg);
        padding-top: 213px;
        padding-bottom: 382px;
    }

}

.main-button {
    font-family: 'PT Serif Caption', serif;
    display: block;
    padding: 20px 40px;
    margin: 0px auto 0px auto;
    width: max-content;

    color: var(--white-color);
    background: rgba(0, 0, 0, 0);
    border: 2px solid #FFFFFF;

    text-transform: uppercase;
    font-weight: 400;
    font-size: 25px;
    line-height: auto;
    letter-spacing: 0.05em;
    text-decoration-line: none;

    box-shadow: 0px 4px 4px 0px #FFFFFF40;

    
    @include for-mobile-only {
        font-size: 22px;
        line-height: 31px;
        padding: 16px 30px;
        
        
    }
    @media screen and (min-width: 320px) and (max-width: 1024px) {
        background: linear-gradient(0deg, #292929, #292929),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    }

    &:hover,
    &:focus,
    &:active {
        color: var(--text-color);
            border: 2px solid var(--white-color);
            cursor: pointer;
            background: linear-gradient(0deg, #292929, #292929),
                linear-gradient(0deg, #FFFFFF, #FFFFFF);
    }
    @include for-small-mobile {
        font-size: 18px;
        padding: 10px 20px;
    }
}

.main-logo {
    @include for-laptop-and-desktop {
        width: 705px;
        height: 375px;
        // margin: 0px 351px 0px 338px;
        margin: 0 auto;
    }
    // @include for-laptop-only{
    //     margin: 0 auto;
    //     // margin: 0 235px 0 235px;
    // }

    @include for-tablet-only {
        width: 596px;
        margin: 0px auto 32px;
    }
    @include for-mobile-only {
        width: 358px;
        height: 276px;
        margin: 0px auto 20px;
    }
   @include for-small-mobile {
       width: 288px;
       height: 256px;
   }
}


.main-logo--tab {
    @include for-laptop-and-desktop {
        width: 650px;
        height: 315px;

        display: block;
        margin: 0 auto;
    }

    @include for-tablet-only {
        // display: none;
        width: 596px;
        height: 305px;
    }

    @include for-mobile-only {
        display: none;
        width: 358px;
        height: 228px;
    }
    @include for-small-mobile {
        width: 288px;
    }
}
.main-logo--mob {
    fill: #FFFFFF;
    @include for-laptop-and-desktop {
        display: none;
    }

    @include for-tablet-only {
        display: none;
    }

    @include for-mobile-only {
        width: 358px;
        height: 228px;
    }

    @include for-small-mobile {
        width: 288px;
    }
}

.header-menu {
    @include for-desktop {
        display: none;
    }
}