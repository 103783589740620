.page-footer {
    background-color: #0E0E0E;
    position: relative;
    @include for-laptop-and-desktop{
        padding-top: 45px;
        padding-bottom: 75px;
    }
    @include for-tablet-only{
        padding-top: 40px;
        padding-bottom: 45px;
    }
    @include for-mobile-only{
        padding-top: 65px;
        padding-bottom: 25px;
    }
}

.footer-line__top {
   @include for-laptop-and-desktop {
    border-top: 2px solid var(--white-color);
        width: 100%;
        position: absolute;
        top: 125px;
        left: 0;
   }
   @include for-mobile-and-tablet {
    display: none;
   }
}
.footer-line__bottom {
    border-top: 2px solid var(--white-color);
    width: 100%;
    position: absolute;
    @include for-laptop-and-desktop{
        bottom: 160px;
        left: 0;
    }
    @include for-tablet-only{
        bottom: 209px;
        left: 0;
    }
    @include for-mobile-only{
        bottom: 202px;
        left: 0;
    }
}
.footer-container{
    @include for-small-mobile {
        min-width: 288px;
    }
}

.footer__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    // align-items: center;
    @include for-laptop-and-desktop{
            padding: 40px 0px;
    }
    @include for-mobile-and-tablet {
        flex-direction: column;
    }
}
// LOCATION-------------------------
.footer-location{
    @include for-laptop-and-desktop {
            display: none;
        }
    @include for-mobile-and-tablet {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        align-items: center;
        }
}

.footer-location__map {
    @include for-tablet-only {
        border: 0;
        min-width: 680px;
        min-height: 328px;
    }

    @include for-mobile-only {
        border: 0;
        width: 357px;
        height: 328px;
    }
    @include for-small-mobile {
        width: 288px;
    }
}
.footer-location__title {
     @include for-mobile-and-tablet {
        font-family: PT Serif Caption;
        text-transform: uppercase;
        font-size: 36px;
        font-weight: 400;
        line-height: 48px;
        letter-spacing: 0.03em;
        text-align: center;
        margin-bottom: 4px;
    }

    @include for-mobile-only {
        font-size: 20px;
        line-height: 27px;
    }
}


// NAV-LIST----------------------------
.footer-nav__list{
display: flex;
justify-content: end;
margin-bottom: 50px;
@include for-mobile-and-tablet {
        display: none;
    }
}
.footer-nav__item:not(:last-child) {
        margin-right: 40px;
    }

.footer-nav__link {
    font-family: 'PT Serif Caption';
    color: var(--text-color);
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.03em;
    text-decoration-line: none;
    padding: 16px 0;

    @include animation(color);

    &:hover,
    &:focus {
        color: var(--hover-color);
    }
}
.address-list__link--flex{
    display: flex;
}
// ADRESS-LIST------------------------
.contact-info {
    @include for-tablet-only {
        width: 680px;
        margin-bottom: 88px;
    }
    @include for-mobile-only {
        margin-bottom: 78px;
    }
}

.address-list {
    display: flex;
    flex-direction: column;
    
    
    @include for-laptop-and-desktop {
    &__item:not(:last-child) {
            margin-bottom: 54px;
        }
}

    @include for-tablet-only {
        &__item:not(:last-child) {
                margin-bottom: 20px;
            }
    }

    @include for-mobile-only {
    &__item:not(:last-child) {
            margin-bottom: 16px;
        }
    }
   
}
.address-list__link {
    font-family: 'Montserrat', sans-serif;
    color: var(--text-color);
    font-weight: 400;
    letter-spacing: 0.05em;
    text-decoration-line: none;
    font-style: normal;

    @include animation(color);

    @include for-laptop-and-desktop {
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0em;
        }

    @include for-tablet-only {
        font-size: 22px;
        line-height: 29px;
    }

    @include for-mobile-only {
        font-size: 16px;
        line-height: 21px; 
    }

    &:hover,
    &:focus {
        color: var(--hover-color);
    }
}
.address-list__link--non-hover {
    &:hover,
    &:focus {
        color: var(--text-color);
    }
}


// ЛОГОТИПИ ФУТЕРА
.law {
    font-family: 'PT Serif Caption';
    color: var(--text-color);
    font-weight: 400;
    letter-spacing: 0.05em;
    
    @include for-laptop-and-desktop {
        // position: absolute;
        // bottom: 0;
        // right: 0;

        // margin-right: 80px;
        // margin-bottom: 47px;
        
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-top: 65px;
    }
    @include for-tablet-only{
        font-size: 20px;
        line-height: 27px;
        text-align: center;
    }
     @include for-mobile-only{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
    }
}
// .footer-logo {
//     @include for-desktop {
//         height: 149px;
//         width: 294px;
//         margin: -167px auto 16px;
//     }
//     @include for-mobile-and-tablet {
//         display: none;
//     }
// }

// .footer-logo--small {
//     @include for-desktop {
//         display: none;
//     }

//     @include for-mobile-and-tablet {
//         margin: 0 auto 32px;
//         text-align: center;
//     }
// }

.footer-logo__link {
    fill: var(--text-color);
    @include animation(fill);
    &:hover,
    &:focus {
        fill: var(--hover-color);
    }
}

.footer-insta {
    // margin: 6px auto 0px;
    //     text-align: center;

    @include for-mobile-and-tablet {
        display: none;
    }
}
.footer-insta__link {
    fill: var(--text-color);
    @include animation(fill);

    &:hover,
    &:focus,
    &:active {
        fill: var(--hover-color);
    }
}
.icon-footer {
    margin-right: 12px;
    padding: 0px;

}


.footer-logo__svg {
    fill:var(--text-color);
    @include animation(fill);
   @include for-laptop-and-desktop{
    width: 357px;
        height: 100px;
        margin-bottom: 8px;
   }
   @include for-mobile-and-tablet{
    width: 260px;
        height: 64px;
   }
    &:hover,
    &:focus,
    &:active {
        fill: var(--hover-color);
    }
}
.footer-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-tablet-only {
        margin-bottom: 20px;
    }
    @include for-mobile-only {
        margin-bottom: 32px;
    }
}