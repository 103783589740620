:root {
  --background-color: #0c0c0c;
  --white-color: #fff;
  --line-color: #474840;
  --accent-background: #706143;
  --light-background: #121212;
  --element-color: #3a3a3a;
  --text-color: #ddd;
  --white-color: #fff;
  --background-btn: #292929;
  --hover-color: #ad9445;
  --accent-color: #bd9e34;
  --line-color: #bb9e36;
}

body {
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  font-family: Montserrat, sans-serif;
}

.section {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  padding: 0 80px;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .container {
    min-width: 1120px;
    padding: 0 80px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .container {
    min-width: 680px;
    padding: 0 32px;
  }
}

@media screen and (max-width: 743px) {
  .container {
    min-width: 358px;
    padding: 0 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .container {
    min-width: 288px;
  }
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, p {
  margin: 0;
}

h2 {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .03em;
  text-align: center;
  font-family: PT Serif Caption, serif;
  font-size: 42px;
  font-weight: 400;
  line-height: 56px;
  display: block;
}

@media screen and (min-width: 744px) and (max-width: 1023px) {
  .header {
    padding-top: 35px;
  }
}

@media screen and (max-width: 743px) {
  .header {
    padding-top: 45px;
  }
}

.header-desktop {
  height: 98px;
  z-index: 100;
  background-color: #0e0e0e;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: fixed;
}

@media screen and (min-width: 1440px) {
  .header-desktop {
    width: 1280px;
    margin: 0 -80px;
    padding: 0 80px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .header-desktop {
    width: 100%;
    margin: 0 -80px;
    padding: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .header-desktop {
    width: 100%;
    margin: 0 -32px;
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .header-desktop {
    display: none;
  }
}

.header-adress {
  display: none;
}

.header-nav, .site-nav {
  display: flex;
}

.site-nav__item:not(:last-child) {
  margin-right: 40px;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .site-nav__item:not(:last-child) {
    margin-right: 20px;
  }
}

.site-nav__link {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .03em;
  padding: 32px 0;
  font-family: PT Serif Caption, serif;
  font-size: 22px;
  font-weight: 400;
  text-decoration-line: none;
  transition-property: color;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .site-nav__link {
    font-size: 20px;
  }
}

.site-nav__link:hover, .site-nav__link:focus, .site-nav__link:active {
  color: var(--hover-color);
}

.header-logo {
  width: 177px;
  height: 42px;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .header-logo {
    width: auto;
  }
}

.header-logo__group {
  fill: #ebe8e8;
  text-decoration: none;
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .header-logo__group {
    margin-left: 80px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .header-logo__group {
    margin-left: 32px;
  }
}

.header-logo__group:hover, .header-logo__group:focus, .header-logo__group:active {
  fill: var(--hover-color);
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .header-logo__svg {
    width: 170px;
  }
}

.header-logo__text {
  color: var(--text-color);
  text-transform: uppercase;
  font-family: PT Serif Caption, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: auto;
  letter-spacing: .03em;
  margin: 5px 0 5px 12px;
}

.header-logo__text:hover, .header-logo__text:focus, .header-logo__text:active {
  color: var(--hover-color);
}

.auth {
  align-items: center;
  display: flex;
}

.auth-insta {
  margin-right: 8px;
}

.auth-insta__link {
  fill: var(--text-color);
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.auth-insta__link:hover, .auth-insta__link:focus, .auth-insta__link:active {
  fill: var(--hover-color);
}

.auth-list {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .auth-list {
    margin-right: 80px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .auth-list {
    margin-right: 32px;
  }
}

.auth-tel__link {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .05em;
  font-family: PT Serif Caption, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-decoration-line: none;
  transition-property: color;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.auth-tel__link:hover, .auth-tel__link:focus, .auth-tel__link:active {
  color: var(--hover-color);
}

.auth-timetable {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .05em;
  font-family: PT Serif Caption, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  display: block;
}

@media (min-width: 1023px) {
  .header-menu {
    display: none;
  }
}

@media (max-width: 1023px) {
  .header-menu {
    justify-content: space-between;
    align-items: center;
    margin-bottom: -60px;
    display: flex;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .header-menu {
    justify-content: end;
  }
}

.js-open-menu {
  width: 44px;
  height: 44px;
  z-index: 1;
  background-color: #181818;
  border: none;
  border-radius: 33px;
  padding: 4px;
  position: fixed;
  box-shadow: 0 8px 8px rgba(0, 0, 0, .25);
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .js-open-menu {
    width: 96px;
    height: 76px;
    padding: 20px;
    top: 35px;
    right: 32px;
  }
}

@media screen and (max-width: 743px) {
  .js-open-menu {
    width: 72px;
    height: 53px;
    padding: 16px 20px;
    top: 40px;
    right: 16px;
  }
}

.open-menu__svg {
  stroke: var(--white-color);
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .open-menu__svg {
    width: 112px;
    height: 72px;
    margin-top: -18px;
    margin-left: -27px;
  }
}

@media screen and (max-width: 743px) {
  .open-menu__svg {
    width: 64px;
    height: 44px;
    margin-top: -11px;
    margin-left: -16px;
  }
}

.open-menu__svg:hover, .open-menu__svg:focus, .open-menu__svg:active {
  stroke: var(--hover-color);
}

.header-menu__adress {
  letter-spacing: .05em;
  text-align: left;
  color: var(--text-color);
  font-family: PT Serif Caption, serif;
  font-weight: 400;
  text-decoration-line: none;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .header-menu__adress {
    width: 447px;
    height: 56px;
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (max-width: 743px) {
  .header-menu__adress {
    width: 248px;
    height: 36px;
    font-size: 14px;
    line-height: 19px;
  }
}

.header-menu__adress:hover, .header-menu__adress:focus, .header-menu__adress:active {
  color: var(--hover-color);
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .header-menu__adress {
    display: none;
  }
}

.menu-container.is-open {
  transform: translateX(0);
}

.is-open .icon__close {
  display: block;
}

.is-open .icon__menu {
  display: none;
}

.menu-container {
  z-index: 2;
  padding-top: 36px;
  padding-bottom: 48px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.menu-container.is-open {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
  position: fixed;
}

.js-close-menu {
  width: 44px;
  height: 44px;
  background-color: rgba(17, 255, 238, 0);
  border: none;
  margin: 0 16px 50px auto;
  padding: 4px;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .js-close-menu {
    margin: 0 32px 50px auto;
  }
}

.menu-info {
  background-color: #3f3f3f;
  flex-direction: column;
  align-items: center;
  margin-bottom: 94px;
  padding: 10px 0;
  display: flex;
}

@media screen and (max-width: 743px) {
  .menu-info {
    margin-bottom: 44px;
  }
}

.menu-info__text {
  letter-spacing: .05em;
  text-align: left;
  margin-left: 12px;
  font-family: PT Serif Caption, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 743px) {
  .menu-info__text {
    font-size: 16px;
    line-height: 21px;
  }
}

.menu-info__item {
  justify-content: flex-start;
  display: flex;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .menu-info__item {
    min-width: 216px;
  }
}

@media screen and (max-width: 743px) {
  .menu-info__item {
    min-width: 195px;
  }
}

.menu-info__item:not(:last-child) {
  margin-bottom: 7px;
}

.menu-info__link {
  color: var(--text-color);
  text-decoration: none;
  display: flex;
}

.menu-nav {
  flex-direction: column;
  align-items: center;
  margin-bottom: 42px;
  display: flex;
}

.menu-nav__item:not(:last-child) {
  margin-bottom: 42px;
}

.menu-nav__link {
  letter-spacing: .03em;
  text-transform: uppercase;
  color: var(--text-color);
  font-family: PT Serif Caption, serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
  text-decoration-line: none;
}

@media screen and (max-width: 743px) {
  .menu-nav__link {
    font-size: 20px;
    line-height: 27px;
  }
}

.menu-nav__link:hover, .menu-nav__link:focus, .menu-nav__link:active {
  color: var(--accent-color);
}

.menu-nav__btn {
  width: max-content;
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .05em;
  text-align: center;
  background: linear-gradient(0deg, #292929, #292929), linear-gradient(0deg, #fff, #fff);
  border: 2px solid #fff;
  margin: 0 auto 40px;
  padding: 20px;
  font-family: PT Serif Caption, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-decoration-line: none;
  display: block;
}

.menu-nav__btn:hover, .menu-nav__btn:focus, .menu-nav__btn:active {
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  cursor: pointer;
  color: var(--text-color);
  border: 2px solid var(--white-color);
  background: linear-gradient(0deg, #292929, #292929), linear-gradient(0deg, #fff, #fff);
}

@media screen and (max-width: 743px) {
  .menu-nav__btn {
    font-size: 18px;
    line-height: 24px;
  }
}

.menu-svg {
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  display: flex;
}

.menu-logo {
  fill: var(--text-color);
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .menu-logo {
    width: 251px;
    height: 77px;
  }
}

@media screen and (max-width: 743px) {
  .menu-logo {
    width: 186px;
    height: 56px;
  }
}

.menu-logo:hover, .menu-logo:focus, .menu-logo:active {
  fill: var(--accent-color);
}

.menu-insta {
  fill: var(--text-color);
  padding: 12px;
}

.menu-insta:hover, .menu-insta:focus, .menu-insta:active {
  fill: var(--accent-color);
}

.close-menu__svg {
  fill: var(--white-color);
}

.close-menu__svg:hover, .close-menu__svg:focus, .close-menu__svg:active {
  fill: var(--accent-color);
}

.overlay {
  max-width: 1440px;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}

@media screen and (min-width: 1440px) {
  .overlay {
    background-image: url("hero-section-desktop.00f6d2e3.jpg");
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .overlay {
    background-image: url("hero-section-desktop.00f6d2e3.jpg");
    padding-top: 180px;
    padding-bottom: 180px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .overlay {
    background-image: url("hero-section-tab.0c88c5cf.jpg");
    padding-top: 329px;
    padding-bottom: 382px;
  }
}

@media screen and (max-width: 743px) {
  .overlay {
    background-image: url("hero-section-mob.c38331d9.jpg");
    padding-top: 213px;
    padding-bottom: 382px;
  }
}

.main-button {
  width: max-content;
  color: var(--white-color);
  text-transform: uppercase;
  font-family: PT Serif Caption, serif;
  font-size: 25px;
  font-weight: 400;
  line-height: auto;
  letter-spacing: .05em;
  background: none;
  border: 2px solid #fff;
  margin: 0 auto;
  padding: 20px 40px;
  text-decoration-line: none;
  display: block;
  box-shadow: 0 4px 4px rgba(255, 255, 255, .25);
}

@media screen and (max-width: 743px) {
  .main-button {
    padding: 16px 30px;
    font-size: 22px;
    line-height: 31px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .main-button {
    background: linear-gradient(0deg, #292929, #292929), linear-gradient(0deg, #fff, #fff);
  }
}

.main-button:hover, .main-button:focus, .main-button:active {
  color: var(--text-color);
  border: 2px solid var(--white-color);
  cursor: pointer;
  background: linear-gradient(0deg, #292929, #292929), linear-gradient(0deg, #fff, #fff);
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .main-button {
    padding: 10px 20px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .main-logo {
    width: 705px;
    height: 375px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .main-logo {
    width: 596px;
    margin: 0 auto 32px;
  }
}

@media screen and (max-width: 743px) {
  .main-logo {
    width: 358px;
    height: 276px;
    margin: 0 auto 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .main-logo {
    width: 288px;
    height: 256px;
  }
}

@media screen and (min-width: 1280px) {
  .main-logo--tab {
    width: 650px;
    height: 315px;
    margin: 0 auto;
    display: block;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .main-logo--tab {
    width: 596px;
    height: 305px;
  }
}

@media screen and (max-width: 743px) {
  .main-logo--tab {
    width: 358px;
    height: 228px;
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .main-logo--tab {
    width: 288px;
  }
}

.main-logo--mob {
  fill: #fff;
}

@media screen and (min-width: 1280px) {
  .main-logo--mob {
    display: none;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .main-logo--mob {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .main-logo--mob {
    width: 358px;
    height: 228px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .main-logo--mob {
    width: 288px;
  }
}

@media screen and (min-width: 1440px) {
  .header-menu {
    display: none;
  }
}

.aboutus__section {
  padding-top: 60px;
  padding-bottom: 65px;
}

@media screen and (min-width: 1280px) {
  .aboutus__section {
    position: relative;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .aboutus__section {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 743px) {
  .aboutus__section {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .about-container {
    min-width: 288px;
  }
}

.aboutus__container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1279px) {
  .aboutus__container {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 1280px) {
  .elem-about {
    width: 350px;
    height: 364px;
    z-index: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 1279px) {
  .elem-about {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .elem-about__svg {
    width: 398px;
    height: 474px;
    fill: var(--element-color);
  }
}

@media screen and (min-width: 1280px) {
  .aboutus__img {
    z-index: 5;
    position: relative;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .aboutus__img {
    width: 446px;
    height: 380px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 743px) {
  .aboutus__img {
    width: 253px;
    height: 217px;
    margin-bottom: 32px;
  }
}

.aboutus__text {
  color: var(--text-color);
  min-width: 690px;
  letter-spacing: .05em;
  text-align: left;
  margin-left: 72px;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 31px;
  display: block;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .aboutus__text {
    min-width: 530px;
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 1279px) {
  .aboutus__text {
    text-align: center;
    color: var(--white-color);
    margin-left: 0;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .aboutus__text {
    width: 680px;
  }
}

@media screen and (max-width: 743px) {
  .aboutus__text {
    min-width: 358px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .aboutus__text {
    min-width: 288px;
    color: var(--white-color);
    font-size: 18px;
  }
}

.aboutus__title {
  width: 210px;
  margin: 0 auto 120px;
  position: relative;
}

.aboutus__title:after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--white-color);
  border-radius: 2px;
  display: inline-block;
  position: absolute;
  bottom: -30%;
  left: 0%;
}

@media screen and (max-width: 743px) {
  .aboutus__title:after {
    height: 2px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .aboutus__title {
    margin: 0 auto 52px;
  }
}

@media screen and (max-width: 743px) {
  .aboutus__title {
    margin: 0 auto 44px;
    font-size: 25px;
    line-height: 33px;
  }
}

.pluses__section {
  padding-top: 130px;
  padding-bottom: 15px;
  font-family: Montserrat, sans-serif;
}

@media screen and (min-width: 1280px) {
  .pluses__section {
    position: relative;
    overflow: hidden;
  }
}

@media screen and (max-width: 743px) {
  .pluses__section {
    padding-top: 66px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .pluses__section {
    min-height: 725px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .pluses-container {
    min-width: 288px;
    min-height: 568px;
  }
}

@media screen and (max-width: 743px) {
  .pluses-container {
    min-height: 568px;
  }
}

@media screen and (min-width: 1280px) {
  .elem-pluses {
    width: 350px;
    height: 364px;
    width: 1070px;
    height: 798px;
    display: block;
    position: absolute;
    top: -100px;
    right: -240px;
  }
}

@media screen and (max-width: 1279px) {
  .elem-pluses {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .elem-pluses__svg {
    width: 1070.5px;
    height: 798px;
    fill: var(--element-color);
  }
}

.pluses__title {
  width: 392px;
  margin: 0 auto 65px;
  position: relative;
}

.pluses__title:after {
  content: "";
  width: 210px;
  height: 2px;
  background-color: var(--white-color);
  border-radius: 2px;
  display: inline-block;
  position: absolute;
  bottom: -30%;
  left: 23%;
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .pluses__title:after {
    left: 15%;
  }
}

@media screen and (min-width: 1440px) {
  .pluses__title {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .pluses__title {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 743px) {
  .pluses__title {
    margin-bottom: 45px;
    font-size: 25px;
    line-height: 33px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .pluses__title {
    width: 288px;
  }
}

.pluses-list {
  justify-content: flex-start;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .pluses-list {
    z-index: 15;
    position: relative;
  }
}

@media (max-width: 743px) {
  .pluses-list {
    display: none;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .pluses-list {
    justify-content: center;
  }
}

.pluses-list__item:not(:last-child) {
  margin-right: 8px;
}

.pluses-list__btn {
  color: var(--text-color);
  letter-spacing: .03em;
  text-align: center;
  color: var(--white-color);
  border: 2px solid var(--white-color);
  cursor: pointer;
  background: none;
  padding: 13px;
  font-family: Montserrat, sans-serif;
  font-size: 17px;
  display: block;
}

@media screen and (min-width: 1280px) {
  .pluses-list__btn {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .pluses-list__btn {
    font-size: 21px;
    font-weight: 500;
    line-height: 29px;
  }
}

.pluses-list__btn:hover, .pluses-list__btn:focus {
  background-color: #292929;
}

.plus-char {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 1279px) {
  .plus-char {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .tabinfo-container {
    width: 592px;
    flex-direction: column;
    align-content: flex-start;
    margin: 0 auto;
    display: flex;
  }
}

.plus-info__name {
  max-width: min-content;
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .03em;
  margin: 48px 0;
  font-size: 32px;
  font-weight: 500;
  display: block;
  position: relative;
}

.plus-info__name:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.plus-info__name:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

@media screen and (max-width: 743px) {
  .plus-info__name {
    display: none;
  }
}

.plus-info__name:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--line-color);
  border-radius: 2px;
  display: inline-block;
  position: absolute;
  bottom: -30%;
  left: 0%;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .plus-info__name {
    display: none;
  }
}

.plus-info__text {
  color: var(--white-color);
  letter-spacing: .03em;
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  display: block;
}

@media screen and (min-width: 1440px) {
  .plus-info__text {
    width: 628px;
    color: var(--text-color);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .plus-info__text {
    width: 548px;
    color: var(--text-color);
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .plus-info__text {
    width: 592px;
    margin: 30px auto 0;
  }
}

@media screen and (max-width: 743px) {
  .plus-info__text {
    text-align: center;
    width: 358px;
    margin: 20px auto;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .plus-info__text {
    width: 288px;
  }
}

.plus-img {
  object-fit: cover;
}

@media screen and (min-width: 1280px) {
  .plus-img {
    z-index: 10;
    height: 394px;
    border-radius: 107px;
    position: relative;
  }
}

@media screen and (min-width: 1440px) {
  .plus-img {
    width: 587px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .plus-img {
    width: 548px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .plus-img {
    width: 592px;
    height: 312px;
    margin: 0 auto;
    display: block;
  }
}

@media screen and (max-width: 743px) {
  .plus-img {
    width: 358px;
    height: 192px;
    margin: 0 auto;
    display: block;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .plus-img {
    width: 288px;
  }
}

.not-active {
  display: none;
}

.mob-btn-list {
  justify-content: center;
  margin: 0 auto 33px;
  display: flex;
}

@media (min-width: 744px) {
  .mob-btn-list {
    display: none;
  }
}

.mob-btn__item:not(:last-child) {
  margin-right: 10px;
}

.mob-btn {
  width: 44px;
  height: 44px;
  border: 1px solid var(--white-color);
  cursor: pointer;
  color: var(--text-color);
  letter-spacing: .03em;
  text-align: center;
  background: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.plus-mob__name {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .03em;
  text-align: center;
  font-weight: 700;
}

@media (min-width: 1280px) {
  .plus-mob__name {
    display: none;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .plus-mob__name {
    width: min-content;
    margin-top: 42px;
    margin-bottom: 36px;
    font-size: 28px;
    line-height: 39px;
    position: relative;
  }

  .plus-mob__name:after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--line-color);
    border-radius: 2px;
    display: inline-block;
    position: absolute;
    bottom: -30%;
    left: 0%;
  }
}

@media screen and (max-width: 743px) {
  .plus-mob__name {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
  }
}

.active {
  background-color: #292929;
}

.barbes__section {
  padding-bottom: 20px;
}

@media screen and (min-width: 1280px) {
  .barbes__section {
    padding-top: 185px;
    position: relative;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barbes__section {
    padding-top: 135px;
  }
}

@media screen and (max-width: 743px) {
  .barbes__section {
    padding-top: 50px;
  }
}

.barbes__title {
  position: relative;
}

@media screen and (min-width: 1280px) {
  .barbes__title {
    width: 535px;
    margin: 0 auto 32px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barbes__title {
    width: 535px;
    margin: 0 auto 42px;
  }
}

@media screen and (max-width: 743px) {
  .barbes__title {
    width: 320px;
    margin: 0 auto 45px;
    font-size: 25px;
    line-height: 33px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .barbes__title {
    width: 288px;
    font-size: 22px;
  }
}

.barbes__title:after {
  content: "";
  width: 210px;
  height: 3px;
  background-color: var(--white-color);
  border-radius: 2px;
  display: inline-block;
  position: absolute;
  bottom: -30%;
  left: 30%;
}

@media screen and (max-width: 743px) {
  .barbes__title:after {
    height: 2px;
  }
}

@media screen and (max-width: 743px) {
  .barbes__title:after {
    width: 150px;
    left: 26%;
  }
}

.barbes__text {
  color: var(--white-color);
  letter-spacing: .03em;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  display: block;
}

@media screen and (min-width: 1280px) {
  .barbes__text {
    width: 780px;
    color: var(--text-color);
    margin: 0 auto 75px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barbes__text {
    width: 680px;
    margin: 0 auto 44px;
  }
}

@media screen and (max-width: 743px) {
  .barbes__text {
    width: 358px;
    margin: 0 auto 43px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .barbes__text {
    width: 288px;
  }
}

.barb__info {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .03em;
  margin-bottom: 4px;
  font-family: PT Serif Caption, serif;
  font-weight: 400;
}

@media screen and (min-width: 1440px) {
  .barb__info {
    font-size: 26px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .barb__info {
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barb__info {
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (max-width: 743px) {
  .barb__info {
    font-size: 20px;
    line-height: 27px;
  }
}

.barb__info--accent {
  color: var(--accent-color);
}

.barbe__expr {
  color: var(--accent-color);
  text-transform: uppercase;
  letter-spacing: .03em;
  font-family: PT Serif Caption, serif;
  font-size: 22px;
  font-weight: 400;
}

@media screen and (min-width: 1440px) {
  .barbe__expr {
    font-size: 22px;
    line-height: 29px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .barbe__expr {
    font-size: 19px;
    line-height: 25px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barbe__expr {
    font-size: 20px;
    line-height: 37px;
  }
}

@media screen and (max-width: 743px) {
  .barbe__expr {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (max-width: 1279px) {
  .barbes-container {
    position: relative;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .barbes-container {
    min-width: 288px;
  }
}

@media screen and (max-width: 1279px) {
  .slider {
    width: 680px;
    height: auto;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
  }
}

@media screen and (max-width: 743px) {
  .slider {
    width: 359px;
    height: auto;
    margin: 20px auto;
    overflow: hidden;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .slider {
    width: 288px;
  }
}

@media screen and (min-width: 1280px) {
  .slider-line {
    text-align: center;
    max-width: 846px;
    justify-content: center;
    margin: 0 auto;
    display: flex;
  }
}

@media screen and (max-width: 1279px) {
  .slider-line {
    transition: all 1s;
    display: flex;
    position: relative;
    left: 0;
  }
}

@media screen and (min-width: 1280px) {
  .barbes-list__item {
    width: 412px;
    flex-direction: column;
    display: flex;
  }

  .barbes-list__item:not(:last-child) {
    margin-right: 24px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .barbes-list__item {
    width: 4357px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barbes-list__item {
    width: 680px;
    height: auto;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 743px) {
  .barbes-list__item {
    width: 359px;
    height: auto;
    text-align: center;
    align-items: center;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .barbes-list__item {
    width: 288px;
  }
}

.barb__card {
  margin-bottom: 20px;
  position: relative;
}

.barb__foto {
  border-radius: 20%;
}

@media screen and (min-width: 1440px) {
  .barb__foto {
    width: 410px;
    height: 427px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .barb__foto {
    width: 357px;
    height: 400px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barb__foto {
    width: 446px;
    height: 466px;
  }
}

@media screen and (max-width: 743px) {
  .barb__foto {
    width: 282px;
    height: 300px;
  }
}

.barb__btn {
  letter-spacing: .05em;
  text-align: center;
  text-transform: uppercase;
  background: none;
  border: 2px solid #fff;
  font-family: PT Serif Caption, serif;
  text-decoration: none;
  display: block;
  position: absolute;
}

@media screen and (min-width: 1280px) {
  .barb__btn {
    max-width: min-content;
    color: var(--text-color);
    padding: 20px 42px;
    font-size: 21px;
    font-weight: 500;
    bottom: 1%;
    left: 20%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .barb__btn {
    left: 17%;
  }
}

@media screen and (max-width: 1279px) {
  .barb__btn {
    color: var(--white-color);
    background-color: var(--background-btn);
    font-weight: 400;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .barb__btn {
    padding: 24px 30px;
    font-size: 24px;
    line-height: 32px;
    bottom: 10px;
    left: 217px;
  }
}

@media screen and (max-width: 743px) {
  .barb__btn {
    padding: 18px 30px;
    font-size: 18px;
    line-height: 24px;
    bottom: 10px;
    left: 22%;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .barb__btn {
    left: 15%;
  }
}

.slider-prev, .slider-next {
  cursor: pointer;
  border: none;
  padding: 0 10px;
}

@media screen and (min-width: 1280px) {
  .slider-prev, .slider-next {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .slider-prev, .slider-next {
    color: var(--text-color);
    background: var(--background-btn);
    border-radius: 12px;
    position: absolute;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .slider-prev, .slider-next {
    width: 56px;
    height: 56px;
  }
}

@media screen and (max-width: 743px) {
  .slider-prev, .slider-next {
    width: 44px;
    height: 44px;
  }
}

@media screen and (max-width: 1279px) {
  .slider-prev {
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 1279px) {
  .slider-next {
    top: 0;
    right: 0;
  }
}

.slider-btn {
  stroke: var(--white-color);
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .slider-btn {
    width: 36px;
    height: 36px;
  }
}

@media screen and (max-width: 743px) {
  .slider-btn {
    width: 24px;
    height: 24px;
  }
}

.slider-btn:hover, .slider-btn:focus, .slider-btn:active {
  stroke: var(--accent-color);
}

@media screen and (min-width: 1280px) {
  .barb-action {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    border-radius: 20%;
    position: absolute;
    top: 0%;
    left: 0%;
  }
}

@media screen and (max-width: 1279px) {
  .barb-action {
    display: none;
  }
}

.barb__card:hover .barb__btn {
  color: var(--text-color);
  border: 2px solid var(--white-color);
  cursor: pointer;
  background: linear-gradient(0deg, #292929, #292929), linear-gradient(0deg, #fff, #fff);
}

.barb__card:hover .barb-action {
  background: none;
}

.barb__card:focus .barb__btn {
  color: var(--text-color);
  cursor: pointer;
  background: linear-gradient(0deg, #292929, #292929), linear-gradient(0deg, #fff, #fff);
}

.barb__card:focus .barb-action {
  background: none;
}

.element-list {
  justify-content: center;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .element-list {
    display: none;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .element-list {
    margin-top: 20px;
  }
}

@media screen and (max-width: 743px) {
  .element-list {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .element-list {
    margin-top: -20px;
  }
}

.element {
  fill: #686868;
}

.current {
  fill: #ad9445;
}

@media screen and (min-width: 1280px) {
  .elem-barb {
    width: 350px;
    height: 364px;
    display: block;
    position: absolute;
    top: -150px;
    left: -15px;
  }
}

@media screen and (max-width: 1279px) {
  .elem-barb {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .elem-barb__svg {
    width: 350px;
    height: 364px;
    fill: var(--element-color);
  }
}

.stars-list {
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.stars-list__item {
  width: 28px;
  height: 28px;
}

.stars-list__item:not(:last-child) {
  margin-right: 16px;
}

.stars-icon {
  stroke: #d9b946;
  fill: #d9b946;
}

@media screen and (min-width: 1280px) {
  .price__section {
    background-color: var(--background-color);
    padding-top: 160px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .price__section {
    padding-top: 50px;
  }
}

@media screen and (max-width: 743px) {
  .price__section {
    padding-top: 40px;
  }
}

@media screen and (max-width: 743px) {
  .price-title {
    font-size: 25px;
    line-height: 33px;
  }
}

.price-list {
  text-align: center;
  width: 412px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .price-list {
    width: 288px;
  }
}

@media screen and (min-width: 1280px) {
  .price-list___kamyfluvan, .price-list___stryzhky, .price-list___different {
    width: 412px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .price-list___kamyfluvan, .price-list___stryzhky, .price-list___different {
    width: 446px;
  }
}

@media screen and (max-width: 743px) {
  .price-list___kamyfluvan, .price-list___stryzhky, .price-list___different {
    width: 358px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .price-list___kamyfluvan, .price-list___stryzhky, .price-list___different {
    width: 288px;
  }
}

.price-list__title {
  text-transform: uppercase;
  color: var(--text-color);
  letter-spacing: .05em;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 600;
  display: block;
  position: relative;
}

@media screen and (min-width: 744px) {
  .price-list__title {
    margin-top: 43px;
  }

  .price-list__title:after {
    content: "";
    width: 83px;
    height: 2px;
    background-color: var(--line-color);
    border-radius: 2px;
    display: inline-block;
    position: absolute;
    top: -33%;
    left: 40%;
  }
}

@media screen and (max-width: 743px) {
  .price-list__title {
    width: min-content;
    margin: 45px auto 29px;
    font-size: 20px;
    line-height: 24px;
  }

  .price-list__title:after {
    content: "";
    width: 100%;
    height: 3px;
    color: var(--line-color);
    border-bottom: dashed;
    display: inline-block;
    position: absolute;
    bottom: -33%;
    left: 0%;
  }

  .price-list__title:before {
    content: "";
    width: 100%;
    height: 3px;
    color: var(--line-color);
    border-top: dashed;
    display: inline-block;
    position: absolute;
    top: -33%;
    left: 0%;
  }
}

@media screen and (min-width: 1280px) {
  .price__container {
    height: 890px;
    border: 2px solid var(--text-color);
    background-image: url("photo-fon.c6e000ac.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    padding-top: 57px;
    padding-bottom: 115px;
    position: relative;
  }
}

@media screen and (min-width: 1440px) {
  .price__container {
    max-width: 1278px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .price__container {
    max-width: 1118px;
  }
}

.price-list__item {
  text-transform: uppercase;
  color: var(--white-color);
  letter-spacing: .05em;
  margin-top: 24px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

@media screen and (min-width: 1280px) {
  .price-list__item {
    color: var(--text-color);
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .price-list__item {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 743px) {
  .price-list__item {
    justify-content: space-between;
    margin-top: 26px;
    font-size: 16px;
    line-height: 20px;
    display: flex;
  }
}

.price-list__item--text-start:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.price-list__item--text-start:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.price-list__item:after {
  content: "..................................................";
  white-space: nowrap;
  font-weight: 200;
  display: block;
  overflow: hidden;
}

@media screen and (max-width: 743px) {
  .price-list__item:after {
    display: none;
  }
}

.service-name {
  float: left;
  margin-left: 2px;
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .service-name {
    max-width: 200px;
  }

  .service-name:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .service-name:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }
}

.service-price {
  float: right;
  margin-right: 2px;
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .service-price:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: right;
  }

  .service-price:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: left;
  }
}

@media screen and (min-width: 1280px) {
  .price-background {
    height: 632px;
    max-width: 1440px;
    background-image: url("gradient.f660a548.png");
    margin-top: -511px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .price-background {
    height: 160px;
  }
}

@media screen and (max-width: 743px) {
  .price-background {
    height: 140px;
  }
}

@media screen and (max-width: 1279px) {
  .price-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), linear-gradient(0deg, #292929, #292929);
  }
}

.line--l {
  height: 815px;
  border-left: 1px solid #474840;
  margin: 155px 0 94px 408px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .line--l {
    margin: 155px 0 94px 328px;
  }
}

@media screen and (max-width: 1279px) {
  .line--l {
    display: none;
  }
}

.line--r {
  height: 815px;
  border-right: 1px solid #474840;
  margin: 155px 408px 94px 0;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .line--r {
    margin: 155px 328px 94px 0;
  }
}

@media screen and (max-width: 1279px) {
  .line--r {
    display: none;
  }
}

.price-text {
  color: var(--text-color);
  letter-spacing: .05em;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .price-text {
    margin-bottom: 27px;
    margin-right: 36px;
    font-size: 21px;
    line-height: 26px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .price-text {
    text-align: center;
    width: 648px;
    margin: 72px auto 46px;
    font-size: 22px;
    line-height: 27px;
  }
}

@media screen and (max-width: 743px) {
  .price-text {
    text-align: center;
    width: 358px;
    margin: 72px auto 28px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .price-text {
    width: 288px;
  }
}

.logo-price__tab-and-mob {
  fill: var(--text-color);
}

@media screen and (min-width: 1280px) {
  .logo-price__tab-and-mob {
    display: none;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .logo-price__tab-and-mob {
    justify-content: center;
    align-items: center;
    padding: 28px 0;
    display: flex;
  }
}

@media screen and (max-width: 743px) {
  .logo-price__tab-and-mob {
    justify-content: center;
    padding: 38px 0;
    display: flex;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .logo-price__first {
    width: 72px;
    height: 72px;
  }
}

@media screen and (max-width: 743px) {
  .logo-price__first {
    width: 57px;
    height: 64px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .logo-price__second {
    width: 274px;
    height: 104px;
  }
}

@media screen and (max-width: 743px) {
  .logo-price__second {
    width: 195px;
    height: 60px;
  }
}

.btn-category {
  display: flex;
}

@media screen and (min-width: 1280px) {
  .btn-category {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 335px;
    left: 20px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .btn-category {
    width: 446px;
    justify-content: space-between;
    margin: 25px auto 0;
  }
}

@media screen and (max-width: 743px) {
  .btn-category {
    width: 358px;
    justify-content: space-between;
    margin: 25px auto 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .btn-category {
    width: 288px;
  }
}

.grand-btn, .master-btn {
  letter-spacing: .03em;
  text-transform: uppercase;
  background: none;
  border: none;
  font-family: PT Serif Caption;
  font-weight: 400;
  transition-property: color;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 1280px) {
  .grand-btn, .master-btn {
    color: var(--text-color);
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .grand-btn, .master-btn {
    color: var(--white-color);
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (max-width: 743px) {
  .grand-btn, .master-btn {
    color: var(--white-color);
    font-size: 19px;
    line-height: 25px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .grand-btn, .master-btn {
    font-size: 17px;
  }
}

.grand-btn:hover, .grand-btn:focus, .grand-btn:active, .master-btn:hover, .master-btn:focus, .master-btn:active, .active-price {
  color: var(--accent-color);
}

@media screen and (max-width: 1279px) {
  .active-price {
    border: 1px solid #fff;
    padding: 10px;
  }
}

.grand-btn {
  transition-property: color;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 1280px) {
  .grand-btn {
    margin-bottom: 25px;
  }
}

.grand-btn:hover, .grand-btn:focus, .grand-btn:active {
  color: var(--accent-color);
}

.not-shown {
  display: none;
}

.reviews {
  padding-top: 40px;
}

@media screen and (min-width: 768px) {
  .reviews {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1280px) {
  .reviews {
    padding-top: 100px;
  }
}

.reviews__container {
  position: relative;
}

.reviews__title {
  text-align: center;
  color: beige;
  margin-bottom: 27px;
  font-family: Montserrat Alternates;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.20833;
}

@media screen and (min-width: 768px) {
  .reviews__title {
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.21875;
  }
}

@media screen and (min-width: 1280px) {
  .reviews__service-wrapper {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.swiper-container {
  overflow: hidden;
}

.swiper-wrapper {
  min-width: 0;
}

.swiper-slide {
  width: 280px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: transform .3s linear;
  display: flex;
}

@media screen and (min-width: 768px) {
  .swiper-slide {
    width: 151px;
    margin-top: 27px;
    margin-bottom: 181.5px;
  }
}

@media screen and (min-width: 1280px) {
  .swiper-slide {
    width: 285px;
    margin-bottom: 109.5px;
  }
}

.slider__img-wrapper {
  text-align: center;
  width: 280px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .slider__img-wrapper {
    width: 151px;
  }
}

@media screen and (min-width: 1280px) {
  .slider__img-wrapper {
    width: 285px;
  }
}

.slider__img {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  transition: scale .3s linear;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .slider__img {
    width: 151px;
    height: 151px;
  }
}

@media screen and (min-width: 1280px) {
  .slider__img {
    width: 285px;
    height: 285px;
  }
}

.reviews__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .reviews__content {
    margin-bottom: 22px;
  }
}

@media screen and (min-width: 1280px) {
  .reviews__content {
    margin-bottom: 28px;
  }
}

.reviews__subtitle {
  text-align: center;
  color: beige;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21429;
}

@media screen and (min-width: 768px) {
  .reviews__subtitle {
    margin-bottom: 19px;
  }
}

@media screen and (min-width: 1280px) {
  .reviews__subtitle {
    font-size: 16px;
    line-height: 1.25;
  }
}

.reviews__article {
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
}

@media screen and (min-width: 320px) and (max-width: 767.98px) {
  .reviews__article {
    max-width: 440px;
  }
}

@media screen and (min-width: 768px) {
  .reviews__article {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .reviews__article {
    font-size: 18px;
    line-height: 1.22222;
  }
}

@media screen and (min-width: 768px) {
  .swiper-slide-active.swiper-slide {
    margin-top: 103px;
    transform: translateY(92.5px);
  }
}

@media screen and (min-width: 1280px) {
  .swiper-slide-active.swiper-slide {
    margin-top: 170px;
    transform: translateY(54.5px);
  }
}

@media screen and (min-width: 768px) {
  .swiper-slide-active .slider__img {
    scale: 2.22517;
  }
}

@media screen and (min-width: 1280px) {
  .swiper-slide-active .slider__img {
    scale: 1.38246;
  }
}

@media screen and (min-width: 768px) {
  .swiper-slide-active .reviews__content {
    transform: translateY(92.5px);
  }
}

@media screen and (min-width: 1280px) {
  .swiper-slide-active .reviews__content {
    transform: translateY(54.5px);
  }
}

.swiper-slide-active .reviews__article {
  overflow: visible;
}

@media screen and (min-width: 768px) {
  .swiper-slide-active .reviews__article {
    width: 704px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .swiper-slide-active .reviews__article {
    width: 2148px;
  }
}

.reviews__pagination-wrapper {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.swiper-button-prev, .swiper-button-next {
  width: 17px;
  height: 17px;
  margin: 0;
  position: static;
}

@media screen and (min-width: 1280px) {
  .swiper-button-prev, .swiper-button-next {
    width: 20px;
  }
}

.swiper-button-prev:after, .swiper-button-next:after {
  width: 35px;
  height: 2px;
  background-color: bisque;
  border: none;
  font-size: 0;
}

.swiper-pagination {
  width: 28px;
  text-align: center;
  color: wheat;
  font-size: 14px;
  line-height: 1.21429;
  position: static;
}

@media screen and (min-width: 1280px) {
  .swiper-pagination {
    width: 35px;
    font-size: 16px;
    line-height: 1.25;
  }
}

.contact__section {
  max-width: 1440px;
  height: 988px;
  background-image: url("contacts-bkgr.faa7bb3f.jpg");
  background-size: cover;
  padding-top: 88px;
  padding-bottom: 27px;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .contact__section {
    height: 763px;
    background-image: url("contact-bkgr-tablet.d8c21058.jpg");
  }
}

@media screen and (max-width: 743px) {
  .contact__section {
    height: 672px;
  }
}

.contact__container {
  max-width: 1280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .contact__container {
    max-width: 1120px;
  }
}

@media screen and (min-width: 1440px) {
  .contact__container {
    height: 808px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .contact__container {
    height: 694px;
    padding-top: 42px;
  }
}

@media screen and (max-width: 743px) {
  .contact__container {
    height: auto;
  }
}

.form__container {
  justify-content: space-between;
  margin-top: 88px;
  display: flex;
}

@media screen and (max-width: 1279px) {
  .form__container {
    margin-top: 0;
  }
}

.form {
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .form {
    width: 446px;
    align-items: center;
    margin: 0 auto;
  }
}

@media screen and (max-width: 743px) {
  .form {
    width: 358px;
    align-items: center;
    margin: 0 auto;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .form {
    width: 288px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .form-title {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 743px) {
  .form-title {
    margin-bottom: 48px;
    font-size: 25px;
  }
}

.form__label {
  background-color: rgba(17, 255, 238, 0);
  margin-bottom: 24px;
}

.form__input {
  color: var(--text-color);
  letter-spacing: .05em;
  width: 519px;
  background-color: rgba(0, 0, 0, .5);
  border: 2px solid #adabab;
  padding: 24px 20px;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .form__input {
    width: 405px;
  }
}

@media screen and (max-width: 743px) {
  .form__input {
    width: 318px;
    font-size: 18px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .form__input {
    width: 228px;
  }
}

.form__text {
  color: var(--text-color);
  letter-spacing: .05em;
  width: 519px;
  background-color: rgba(0, 0, 0, .5);
  border: 2px solid #adabab;
  padding: 24px 20px 0;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .form__text {
    width: 405px;
  }
}

@media screen and (max-width: 743px) {
  .form__text {
    width: 318px;
    font-size: 18px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .form__text {
    width: 228px;
  }
}

.form__input, .form__text {
  outline: none;
}

.form__input:hover, .form__text:hover {
  border: 2px solid #fff;
}

.form__input:focus, .form__input:active, .form__text:focus, .form__text:active {
  border: 2px solid #ad9445;
  box-shadow: 4px 4px 4px rgba(255, 226, 73, .16);
}

.form__button {
  width: max-content;
  color: var(--text-color);
  text-transform: uppercase;
  font-family: PT Serif Caption, serif;
  font-size: 22px;
  font-weight: 400;
  line-height: auto;
  letter-spacing: .05em;
  cursor: pointer;
  background-color: var(--background-color);
  display: block;
}

@media screen and (min-width: 1280px) {
  .form__button {
    border: 0;
    padding: 20px 26px;
  }
}

@media screen and (max-width: 1279px) {
  .form__button {
    border: 1px;
    padding: 20px 80px;
  }
}

@media screen and (max-width: 743px) {
  .form__button {
    margin-bottom: 105px;
    font-size: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .form__button {
    padding: 20px 60px;
  }
}

.form__button:hover, .form__button:focus, .form__button:active {
  color: var(--background-color);
  background-color: var(--white-color);
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

@media screen and (max-width: 1279px) {
  .location {
    display: none;
  }
}

.location__text {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: .05em;
  text-align: center;
  margin-bottom: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.location__map {
  width: 519px;
  height: 385px;
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .location__map {
    width: 476px;
  }
}

.page-footer {
  background-color: #0e0e0e;
  position: relative;
}

@media screen and (min-width: 1280px) {
  .page-footer {
    padding-top: 45px;
    padding-bottom: 75px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .page-footer {
    padding-top: 40px;
    padding-bottom: 45px;
  }
}

@media screen and (max-width: 743px) {
  .page-footer {
    padding-top: 65px;
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1280px) {
  .footer-line__top {
    border-top: 2px solid var(--white-color);
    width: 100%;
    position: absolute;
    top: 125px;
    left: 0;
  }
}

@media screen and (max-width: 1279px) {
  .footer-line__top {
    display: none;
  }
}

.footer-line__bottom {
  border-top: 2px solid var(--white-color);
  width: 100%;
  position: absolute;
}

@media screen and (min-width: 1280px) {
  .footer-line__bottom {
    bottom: 160px;
    left: 0;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .footer-line__bottom {
    bottom: 209px;
    left: 0;
  }
}

@media screen and (max-width: 743px) {
  .footer-line__bottom {
    bottom: 202px;
    left: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .footer-container {
    min-width: 288px;
  }
}

.footer__flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .footer__flex {
    padding: 40px 0;
  }
}

@media screen and (max-width: 1279px) {
  .footer__flex {
    flex-direction: column;
  }
}

@media screen and (min-width: 1280px) {
  .footer-location {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .footer-location {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    display: flex;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .footer-location__map {
    min-width: 680px;
    min-height: 328px;
    border: 0;
  }
}

@media screen and (max-width: 743px) {
  .footer-location__map {
    width: 357px;
    height: 328px;
    border: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 389px) {
  .footer-location__map {
    width: 288px;
  }
}

@media screen and (max-width: 1279px) {
  .footer-location__title {
    text-transform: uppercase;
    letter-spacing: .03em;
    text-align: center;
    margin-bottom: 4px;
    font-family: PT Serif Caption;
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
  }
}

@media screen and (max-width: 743px) {
  .footer-location__title {
    font-size: 20px;
    line-height: 27px;
  }
}

.footer-nav__list {
  justify-content: end;
  margin-bottom: 50px;
  display: flex;
}

@media screen and (max-width: 1279px) {
  .footer-nav__list {
    display: none;
  }
}

.footer-nav__item:not(:last-child) {
  margin-right: 40px;
}

.footer-nav__link {
  color: var(--text-color);
  letter-spacing: .03em;
  padding: 16px 0;
  font-family: PT Serif Caption;
  font-size: 20px;
  font-weight: 400;
  text-decoration-line: none;
  transition-property: color;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.footer-nav__link:hover, .footer-nav__link:focus {
  color: var(--hover-color);
}

.address-list__link--flex {
  display: flex;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .contact-info {
    width: 680px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 743px) {
  .contact-info {
    margin-bottom: 78px;
  }
}

.address-list {
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .address-list__item:not(:last-child) {
    margin-bottom: 54px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .address-list__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 743px) {
  .address-list__item:not(:last-child) {
    margin-bottom: 16px;
  }
}

.address-list__link {
  color: var(--text-color);
  letter-spacing: .05em;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: none;
  transition-property: color;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 1280px) {
  .address-list__link {
    letter-spacing: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .address-list__link {
    font-size: 22px;
    line-height: 29px;
  }
}

@media screen and (max-width: 743px) {
  .address-list__link {
    font-size: 16px;
    line-height: 21px;
  }
}

.address-list__link:hover, .address-list__link:focus {
  color: var(--hover-color);
}

.address-list__link--non-hover:hover, .address-list__link--non-hover:focus {
  color: var(--text-color);
}

.law {
  color: var(--text-color);
  letter-spacing: .05em;
  font-family: PT Serif Caption;
  font-weight: 400;
}

@media screen and (min-width: 1280px) {
  .law {
    text-align: center;
    margin-top: 65px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .law {
    text-align: center;
    font-size: 20px;
    line-height: 27px;
  }
}

@media screen and (max-width: 743px) {
  .law {
    text-align: center;
    font-size: 16px;
    line-height: 21px;
  }
}

.footer-logo__link {
  fill: var(--text-color);
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.footer-logo__link:hover, .footer-logo__link:focus {
  fill: var(--hover-color);
}

@media screen and (max-width: 1279px) {
  .footer-insta {
    display: none;
  }
}

.footer-insta__link {
  fill: var(--text-color);
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.footer-insta__link:hover, .footer-insta__link:focus, .footer-insta__link:active {
  fill: var(--hover-color);
}

.icon-footer {
  margin-right: 12px;
  padding: 0;
}

.footer-logo__svg {
  fill: var(--text-color);
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 1280px) {
  .footer-logo__svg {
    width: 357px;
    height: 100px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1279px) {
  .footer-logo__svg {
    width: 260px;
    height: 64px;
  }
}

.footer-logo__svg:hover, .footer-logo__svg:focus, .footer-logo__svg:active {
  fill: var(--hover-color);
}

.footer-icon {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 744px) and (max-width: 1279px) {
  .footer-icon {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 743px) {
  .footer-icon {
    margin-bottom: 32px;
  }
}

/*# sourceMappingURL=index.62005eea.css.map */
