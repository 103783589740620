.aboutus__section {
    padding-top: 60px;
    padding-bottom: 65px;
    @include for-laptop-and-desktop {
        position: relative;
    }
    @include for-tablet-only{
        padding-bottom: 0px;
    }
    @include for-mobile-only{
        padding-bottom: 0px;
    }
}
.about-container{
    @include for-small-mobile {
            min-width: 288px;
        }
}
.aboutus__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include for-mobile-and-tablet {
        flex-direction: column;
        align-items: center;
        }
}
  .elem-about {
      @include for-laptop-and-desktop {
          width: 350px;
          height: 364px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
      }

      @include for-mobile-and-tablet {
          display: none;
      }
  }

  .elem-about__svg {
      @include for-laptop-and-desktop {
          width: 398px;
          height: 474px;
          fill: var(--element-color);
      }
  }
.aboutus__img {
    @include for-laptop-and-desktop {
        position: relative;
        z-index: 5;
    }
    @include for-tablet-only {
        width: 446px;
        height: 380px;
        margin-bottom: 56px;
        }
    @include for-mobile-only {
        width: 253px;
        height: 217px;
        margin-bottom: 32px;
        }
}

.aboutus__text {
    font-family: 'Montserrat', sans-serif;
    display: block;
    color: var(--text-color);
    font-weight: 400;
    min-width: 690px;
    font-size: 22px;
    line-height: 31px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-left: 72px;
    
    @include for-laptop-only {
        font-size: 20px;
        line-height: 28px;
        min-width: 530px;
    }

    @include for-mobile-and-tablet{
            text-align: center;
            margin-left: 0px;
            color: var(--white-color);
    }
    @include for-tablet-only {
            width: 680px;
        }
    @include for-mobile-only {
            font-size: 18px;
            line-height: 22px;
            min-width: 358px;
        }
    @include for-small-mobile {
        font-size: 18px;
        min-width: 288px;
        color: var(--white-color);
    }
}

.aboutus__title {
    position: relative;
    width: 210px;
    margin: 0px auto 120px;
        &::after {
                position: absolute;
                bottom: -30%;
                left: 0%;
                content: "";
                display: inline-block;
                width: 100%;
                height: 3px;
                background-color: var(--white-color);
                border-radius: 2px;
                @include for-mobile-only{
                    height: 2px;
                }
            }
    @include for-tablet-only {
        margin: 0px auto 52px;
        }
     @include for-mobile-only {
            font-size: 25px;
            line-height: 33px;
            margin: 0px auto 44px;
    
        }
}


