
@mixin for-mobile-only {
    @media screen and (max-width: 743px) {
        @content;
    }
}
@mixin for-mobile-and-tablet {
    @media screen and (max-width: 1279px) {
        @content;
    }
}
@mixin for-tablet-only {
    @media screen and (min-width: 744px) and (max-width: 1279px) {
        @content;
    }
}
@mixin for-laptop-only {
    @media screen and (min-width: 1280px) and (max-width: 1439px) {
        @content;
    }
}
@mixin for-laptop-and-desktop {
    @media screen and (min-width: 1280px) {
        @content;
    }
}
@mixin for-desktop {
    @media screen and (min-width: 1440px) {
        @content;
    }
}

@mixin for-small-mobile {
    @media screen and (min-width: 320px) and (max-width: 389px) {
        @content;
    }
}


@mixin animation($property) {
    transition-property: $property;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}