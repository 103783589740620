@import './variable';
@import './mixin';
@import './basestyles';
@import './components/header';
@import './components/menu';
@import './components/hero-section';
@import './components/aboutus';
@import './components/pluses';
@import './components/slider-barbs';
@import './components/price';
@import './components/gallery';
@import './components/swiper';
@import './components/contscts';
@import './components/footer';
