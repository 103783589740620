.header {
    @media screen and (min-width: 744px) and (max-width: 1023px) {
        padding-top: 35px;
        }
@include for-mobile-only{
    padding-top: 45px;
}
}
.header-desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 98px;
    background-color: #0E0E0E;

    position: fixed;
    z-index: 100;

    @include for-desktop {
        padding: 0px 80px;
        margin: 0px -80px;
        width: 1280px;
    }
    
    @include for-laptop-only {
        // min-width: 1120px;
        width: 100%;
        padding: 0px;
        margin: 0px -80px;

    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        width: 100%;
        padding: 0px;
        margin: 0px -32px;
        }
    // @include for-mobile-and-tablet {
    //     display: none;
    // }
     @media (max-width: 1023px) {
        display: none;
        }
}

.header-adress {
    display: none;
}

// НАВІГАЦІЯ
.header-nav {
    display: flex;
}

.site-nav {
    display: flex;
    // margin: 0px 65px 0px 70px;
    &__item:not(:last-child) {
        margin-right: 40px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            margin-right: 20px;
        }
        }
}
 .site-nav__link {
    font-family: 'PT Serif Caption', serif;
     display: block;
     padding: 32px 0;
     color: var(--text-color);
     text-transform: uppercase;
     font-weight: 400;
     font-size: 22px;
     letter-spacing: 0.03em;
     text-decoration-line: none;

    @include animation(color);
     
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            font-size: 20px;
        }

     &:hover,
     &:focus,
     &:active {
         color: var(--hover-color);
     }
 }
// ЛОГО
 .header-logo {
     width: 177px;
     height: 42px;
     @include for-laptop-only {
        width: auto;
     }
    }
    
.header-logo__group{
    display: flex;
    text-decoration: none;
    fill: #EBE8E8;

    @include animation(fill);
    
    @include for-laptop-only {
        margin-left: 80px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
            margin-left: 32px;
        }
    &:hover,
    &:focus,
    &:active {
        fill: var(--hover-color);}
}
.header-logo__svg{
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
            width: 170px;
        }
}

.header-logo__text {
    font-family: 'PT Serif Caption', serif;
    font-weight: 400;
    margin: 5px 0px 5px 12px;
    color: var(--text-color);
    text-transform: uppercase;
    font-size: 14px;
    line-height: auto;
    letter-spacing: 0.03em;
    &:hover,
    &:focus,
    &:active {
         color: var(--hover-color);}
    
}

// ББЛОК-ІНФО
.auth {
    display: flex;
    align-items: center;
}
.auth-insta {
    margin-right: 8px;
    
}

.auth-insta__link {
    fill: var(--text-color);
    @include animation(fill);

    &:hover,
    &:focus,
    &:active {
        fill: var(--hover-color);
    }
}

.auth-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-laptop-only {
        margin-right: 80px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        margin-right: 32px;
    }
}

 .auth-tel__link{
    font-family: 'PT Serif Caption', serif;
     display: block;
     color: var(--text-color);
     text-transform: uppercase;
     font-weight: 700;
     font-size: 18px;
     line-height: 25px;
     letter-spacing: 0.05em;
     text-decoration-line: none;

    @include animation(color);

     &:hover,
     &:focus,
    &:active {
         color: var(--hover-color);
     }
 }

  .auth-timetable{
    font-family: 'PT Serif Caption', serif;
      display: block;
      color: var(--text-color);
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.05em;
  }

//  Для планшету і моб.

.header-menu {
//   @include for-desktop {
//     display: none;
//   }
   @media (min-width: 1023px) {
       display: none;
   }
   @media (max-width: 1023px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: -60px;  
   }
//   @include for-mobile-and-tablet {
//     display: flex;
//         justify-content: space-between;
//         align-items: center;
//         margin-bottom: -60px;      
//   }
  @include for-tablet-only {
    // margin-top: 35px;
  }
    @include for-mobile-only {
//   margin-top: 45px;     
  }
  @include for-small-mobile {
    justify-content: end;
  }
}
.js-open-menu {
    width: 44px;
    height: 44px;
    padding: 4px;
    // background-color: #1fe0;
    border: none;
    border-radius: 33px;
    background-color: #181818;
    box-shadow: 0px 8px 8px 0px #00000040;

    position: fixed;
    z-index: 1;

    @include for-tablet-only {
        width: 96px;
        height: 76px;
        padding: 20px;
        right: 32px;
        top: 35px;

    }
    @include for-mobile-only {
        width:72px;
        height:53px;
        padding: 16px 20px 16px 20px;
        right: 16px;
        top: 40px;
                   
    }
}
.open-menu__svg {
    stroke: var(--white-color);
    @include for-tablet-only {
        width: 112px;
        height: 72px;
        margin-top: -18px;
        margin-left: -27px;
    }
    @include for-mobile-only {
        width: 64px;
        height: 44px;
        margin-top: -11px;
        margin-left: -16px;
    }
    &:hover,
    &:focus,
    &:active {
        stroke: var(--hover-color);
     }
}

.header-menu__adress {
    font-family: 'PT Serif Caption', serif;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-align: left;
    color: var(--text-color);
    text-decoration-line: none;

    @include for-tablet-only {
        font-size: 24px;
        line-height: 32px;
        width: 447px;
        height: 56px;
    }
    @include for-mobile-only {
        font-size: 14px;
        line-height: 19px;
        width: 248px;
        height: 36px;
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--hover-color);
     }
     @include for-small-mobile {
        display: none;
     }
}