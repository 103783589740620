:root {
    --background-color: #0C0C0C;
    --white-color: #FFFFFF;
    --line-color: #474840;
    // --accent-color: #A18753;
    // --hover-color: #AD9445;
    --accent-background: #706143;
    --light-background: #121212;


    --element-color: #3A3A3A;
    --text-color:#DDDDDD;
    --white-color: #FFFFFF;
    --background-btn: #292929;
    --hover-color: #AD9445;
    --accent-color: #BD9E34;
    --line-color: #BB9E36;

    




}