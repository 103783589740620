.price__section {
    @include for-laptop-and-desktop {
        padding-top: 160px;
        background-color: var(--background-color);
    }
    @include for-tablet-only {
        padding-top: 50px;
    }
    
    
    // padding-bottom: 120px;
    @include for-mobile-only{
        padding-top: 40px;
        // background-color: #121212; стара версія
    }
}

.price-title{
    @include for-mobile-only{
        font-size: 25px;
        line-height: 33px;
    }
    // @include for-small-mobile {
    //     font-size: 22px;
    // }
}
.price-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // border: 2px solid var(--accent-color);
    width: 412px;
    margin: 0 auto;
    @include for-small-mobile {
        width: 288px;
    }
}


.price-list___kamyfluvan, 
.price-list___stryzhky,
.price-list___different {
    @include for-laptop-and-desktop {
        width: 412px;
    }
    @include for-tablet-only {
        width: 446px;
    }
    @include for-mobile-only {
        width: 358px;
    }
    @include for-small-mobile {
        width: 288px;
    }
}
.price-list__title {
    font-family: 'Montserrat', sans-serif;
    display: block;
    text-transform: uppercase;
    color: var(--text-color);
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.05em;
    position: relative;

    @media screen and (min-width: 744px) {
            margin-top: 43px;
        
            &::after {
                position: absolute;
                top: -33%;
                left: 40%;
                content: "";
                display: inline-block;
                width: 83px;
                height: 2px;
                background-color: var(--line-color);
                border-radius: 2px;
            }
    }
    @include for-mobile-only {
            font-size: 20px;
            line-height: 24px;
            width: min-content;
            margin: 0px auto 29px;
            margin-top: 45px;

            &::after {
                    position: absolute;
                    bottom: -33%;
                    left: 0%;
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 3px;
                    color: var(--line-color);
                    border-bottom: dashed;
                    // background-color: var(--accent-color);
                    // border-bottom: 2px;
                    // border-style:dashed;
                }
            &::before {
                    position: absolute;
                    top: -33%;
                    left: 0%;
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 3px;
                    color: var(--line-color);
                    border-top: dashed;
                    // background-color: var(--accent-color);
                    // border-radius: 2px;
                }
            
    }
}


.price__container {
    @include for-laptop-and-desktop {
    position: relative;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    padding-top: 57px;
    padding-bottom: 115px;

    
    height: 890px;
    background-image: url(/src/images/price/photo-fon.jpg);
    border: 2px solid var(--text-color);
    }

    @include for-desktop{
        max-width: 1278px;
    }
    @include for-laptop-only{
        max-width: 1118px;
    }
}
.price-list__item {
    font-family: 'Montserrat', sans-serif;
    // display: block;
    text-transform: uppercase;
    color: var(--white-color);
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 24px;
    
    @include for-laptop-and-desktop {
        font-size: 18px;
        line-height: 22px;
        overflow: hidden;
        color: var(--text-color);
    }
    @include for-tablet-only {
        font-size: 20px;
        line-height: 24px;
    }
    @include for-mobile-only {
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        margin-top: 26px;
    }
}
.price-list__item--text-start {
    text-align: start;
}

// Крапки між назвою послуги і ціною
.price-list__item:after {
    content: '..................................................';
    font-weight: 200;
    display: block;
    white-space: nowrap;
    overflow: hidden;

    @include for-mobile-only {
        display: none;
    }
}

.service-name {
    float: left;
    margin-left: 2px;
    @include for-small-mobile {
        text-align: start;
        max-width: 200px;
    }
    // @include for-mobile-only {
    //     font-size: 16px;
    //     line-height: 20px;
    //     margin-top: 26px;
    //     margin-left: 0px;
    // }
}
.service-price {
    float: right;
    margin-right: 2px;
    @include for-small-mobile {
        text-align: end;
    }
}


// Позиційовані елементи
.price-background {

    @include for-laptop-and-desktop {
        // background-color: #515151;
        height: 632px;
        max-width: 1440px;
        margin-top: -511px;
        background-image: url(/src/images/price/gradient.png);
    }
    @include for-tablet-only {
        // background-color: #404040;
        height: 160px;
    }
    @include for-mobile-only {
        // background-color: #404040;
        height: 140px;
    }
    @include for-mobile-and-tablet {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                linear-gradient(0deg, #292929, #292929);
    }
}
.line--l {
    border-left: 1px solid #474840;
    margin: 155px 0px 94px 408px;
    height: 815px;

    position: absolute;
    top: 0;
    left: 0;

    @include for-laptop-only{
        margin: 155px 0px 94px 328px;
    }
    @include for-mobile-and-tablet {
        display: none;
    }

}
.line--r {
    border-right: 1px solid #474840;
    margin: 155px 408px 94px 0px;

    position: absolute;
    height: 815px;
    top: 0;
    right: 0;

    @include for-laptop-only{
        margin: 155px 328px 94px 0px;;
    }
    @include for-mobile-and-tablet {
        display: none;
    }
}
.price-text {
    font-family: 'Montserrat', sans-serif;
    color: var(--text-color);
    font-weight: 400;
    letter-spacing: 0.05em;

    @include for-laptop-and-desktop {
    position: absolute;
    bottom: 0;
    right: 0;

    margin-right: 36px;
    margin-bottom: 27px;

    font-size: 21px;
    line-height: 26px;
    }
    
    @include for-tablet-only {
        font-size: 22px;
        line-height: 27px;

        margin: 72px auto 46px;
        text-align: center;
        width: 648px;
    }


    @include for-mobile-only {
        font-size: 16px;
        line-height: 20px;

        margin: 72px auto 28px;
        text-align: center;
        width: 358px;
    }
    @include for-small-mobile {
        width: 288px;
    }
    
}
// .logo-price__desktop {
//     position: absolute;
//     top: 0;
//     left: 0;
//     margin-top: 25px;
//     margin-left: 56px;
//     height: 192px;
//     width: 333px;
//     fill: #3D3C3D;

//     @include for-mobile-and-tablet {
//         display: none;
//     }
// }

.logo-price__tab-and-mob {
    @include for-laptop-and-desktop {
        display: none;
    }
    @include for-tablet-only {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 28px 0px;
    }
    @include for-mobile-only {
        display: flex;
        justify-content: center;
        padding: 38px 0px;
    }
    fill: var(--text-color);
}

.logo-price__first {
    @include for-tablet-only {
        width: 72px;
        height: 72px;
    }
    @include for-mobile-only {
        width: 57px;
        height: 64px;
    }
}
.logo-price__second {
    @include for-tablet-only {
        width: 274px;
        height: 104px;
    }
    @include for-mobile-only {
        width: 195px;
        height: 60px;
    }
}



.btn-category {
    display: flex;

    @include for-laptop-and-desktop {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
            top: 335px;
            left: 20px;
    }

    @include for-tablet-only {
    justify-content: space-between;
        width: 446px;
        margin: 25px auto 0px;
    }
    @include for-mobile-only{
    justify-content: space-between;
        width: 358px;
        margin: 25px auto 0px;
    }
    @include for-small-mobile {
        width: 288px;
    }
}
.grand-btn,
.master-btn{
    font-family: PT Serif Caption;
    font-weight: 400;
    letter-spacing: 0.03em;

    text-transform: uppercase;
    background: transparent;
    border: none;

    @include for-laptop-and-desktop {
            font-size: 28px;
            line-height: 37px;
            color: var(--text-color);
    }
    @include for-tablet-only{
            font-size: 24px;
            line-height: 32px;
            color: var(--white-color);
    }
    @include for-mobile-only {
            font-size: 19px;
            line-height: 25px;
            color: var(--white-color);
    }
    @include for-small-mobile {
        font-size: 17px;
    }

    @include animation(color);

    &:hover,
    &:focus,
    &:active {
    color: var(--accent-color);;
     }
}

.active-price {
    color: var(--accent-color);
    @include for-mobile-and-tablet {
        border: 1px solid #FFFFFF;
        padding: 10px;
    }
}
.grand-btn {
    @include for-laptop-and-desktop {
        margin-bottom: 25px;
    }
    @include animation(color);
    
    &:hover,
    &:focus,
    &:active {
    color: var(--accent-color);
    }
}
.not-shown {
    display: none;
}