.pluses__section {
    font-family: 'Montserrat', sans-serif;
    padding-top: 130px;
    padding-bottom: 15px;
    @include for-laptop-and-desktop {
        position: relative;
        overflow: hidden;
    }
    @include for-mobile-only{
        padding-top: 66px;
    }
    @include for-tablet-only {
            min-height: 725px;
    }
}
.pluses-container{
    @include for-small-mobile {
        min-width: 288px;
        min-height: 568px;
    }
    @include for-mobile-only {
        min-height: 568px;
    }
}
.elem-pluses {
    @include for-laptop-and-desktop {
        width: 350px;
        height: 364px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 1070px;
        height: 798px;
        top: -100px;
        right: -240px;
    }

    @include for-mobile-and-tablet {
        display: none;
    }
}

.elem-pluses__svg {
    @include for-laptop-and-desktop {
          width: 1070.4999679311143px; 
        height: 798px;
        fill: var(--element-color);
    }
}

.pluses__title {
    position: relative;
    width: 392px;
    margin: 0px auto 65px;

    &::after {
        position: absolute;
        bottom: -30%;
        left: 23%;
        content: "";
        display: inline-block;
        width: 210px;
        height: 2px;
        background-color: var(--white-color);
        border-radius: 2px;
        
        @include for-small-mobile {
            left: 15%;
        }
    }
    @include for-desktop {
        margin-bottom: 80px;
    }
    @include for-tablet-only {
        margin-bottom: 40px;
    }
    @include for-mobile-only {
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 45px;
    }
    @include for-small-mobile {
        width: 288px;
    }
}

.pluses-list{
    display: flex;
    justify-content: flex-start;
    @include for-laptop-and-desktop {
        position: relative;
        z-index: 15;
    }
    @media (max-width: 743px) {
        display: none;
        }
        @include for-tablet-only {
            justify-content: center;
        }
}
.pluses-list__item:not(:last-child) {
    margin-right: 8px;
}
.pluses-list__btn {
    padding: 13px;
    font-family: 'Montserrat', sans-serif;
    display: block;
    color: var(--text-color);
    font-size: 17px;
    letter-spacing: 0.03em;
    text-align: center;
    color: var(--white-color);
    background: rgba(0, 0, 0, 0);
    border: 2px solid var(--white-color);
    cursor: pointer;

    @include for-laptop-and-desktop {
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        text-transform: uppercase;
        // min-width: 200px;
    }
    @include for-tablet-only{
        font-size: 21px;
        line-height: 29px;
        font-weight: 500;
    }



    &:hover,
    &:focus {
        background-color: #292929;
    }

}


.plus-char {
    display: flex;
    justify-content: space-between;

    @include for-mobile-and-tablet {
        flex-direction: column-reverse;
    }
}

.tabinfo-container {
    @include for-tablet-only {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        width: 592px;
        margin: 0px auto;
    }
}
.plus-info__name {
    position: relative;

    max-width: min-content;
    margin: 48px 0px;
    // font-family: 'Montserrat', sans-serif;
    display: block;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 0.03em;
    text-align: start;

    @include for-mobile-only {
        display: none;
    }

    &::after {
        position: absolute;
        bottom: -30%;
        left: 0%;
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: var(--line-color);
        border-radius: 2px;
    }

    @include for-tablet-only {
    display: none;
        }
}

.plus-info__text {
    // font-family: 'Montserrat', sans-serif;
    display: block;
    color: var(--white-color);
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0.03em;
    line-height: 27px;

    @include for-desktop {
        width: 628px;
        color: var(--text-color);
    }

    @include for-laptop-only {
        font-size: 20px;
        line-height: 24px;
        width: 548px;
        color: var(--text-color);
    }

    @include for-tablet-only {
        width: 592px;
        margin: 30px auto 0px;
        }
    @include for-mobile-only {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        width: 358px;
        margin: 20px auto;
    }
    @include for-small-mobile {
        width: 288px;
    }
}

.plus-img {
    object-fit: cover;
    @include for-laptop-and-desktop {
        border-radius: 107px;
        position: relative;
        z-index: 10;
        height: 394px;
    }
    @include for-desktop {
        width: 587px;
    }
    @include for-laptop-only {
        width: 548px;
    }
    @include for-tablet-only {
        display: block;
        margin: 0px auto;
        width: 592px;
        height: 312px;   
        }
    @include for-mobile-only {
        display: block;
        margin: 0px auto;
        width: 358px;
            height: 192px;
    }
    @include for-small-mobile {
        width: 288px;
    }
}

.not-active {
    display: none;
}

// КНОПКИ ДЛЯ МОБІЛЬНОЇ ВЕРСІЇ

.mob-btn-list {
    @media (min-width: 744px) {
            display: none;
    }
    display: flex;
    justify-content: center;
    margin: 0px auto 33px;
}

.mob-btn__item {
    &:not(:last-child) {
            margin-right: 10px;
        }
}

.mob-btn {
    width: 44px;
    height: 44px;
    // padding: 13px 18px 13px 18px;
    // border: 1px solid #BB9E36;
    // background-color: #1fe0;
    background: rgba(0, 0, 0, 0);
    border: 1px solid var(--white-color);
    cursor: pointer;

    // font-family: 'Montserrat', sans-serif;
    color: var(--text-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-align: center;
}

.plus-mob__name {
    @media (min-width: 1280px) {
            display: none;
        }
    // font-family: 'Montserrat', sans-serif;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-align: center;
    

    @include for-tablet-only {
        position: relative;
        font-size: 28px;
        line-height: 39px;
        width: min-content;
        margin-bottom: 36px;
        margin-top: 42px;
            &::after {
                    position: absolute;
                    bottom: -30%;
                    left: 0%;
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 1px;
                    background-color: var(--line-color);
                    border-radius: 2px;
                }
 

    }
    @include for-mobile-only {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 8px;
    }
}

.active {
    background-color: #292929;
}



