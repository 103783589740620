// ЗАГОЛОВОК І ТЕКСТ
.barbes__section {
    
    padding-bottom: 20px;
    @include for-laptop-and-desktop {
        position: relative;
        padding-top: 185px;
    }
    @include for-tablet-only{
        padding-top: 135px;
    }
    @include for-mobile-only {
        padding-top: 50px;
    }
}

.barbes__title {
    position: relative;
    

    @include for-laptop-and-desktop {
        width: 535px;
        margin: 0px auto 32px;
    }
    @include for-tablet-only {
        width: 535px;
        margin: 0px auto 42px;
    }
    @include for-mobile-only {
        font-size: 25px;
        line-height: 33px;

        width: 320px;
        margin: 0px auto 45px;
    }
    @include for-small-mobile {
        font-size: 22px;
        width: 288px;
    }

    &::after {
        position: absolute;
        bottom: -30%;
        left: 30%;
        content: "";
        display: inline-block;
        width: 210px;
        height: 3px;
        background-color: var(--white-color);
        border-radius: 2px;
        @include for-mobile-only {
            height: 2px;
        }

        @include for-mobile-only {
            width: 150px;
            left: 26%;
        }
    }
}

.barbes__text {
    font-family: 'Montserrat', sans-serif;
    display: block;
    color: var(--white-color);
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0.03em;
    line-height: 27px;
    // text-align: left;
    text-align: center;


    @include for-laptop-and-desktop {
        // text-align: center;
        width: 780px;
        margin: 0px auto 75px;
        color: var(--text-color);
    }

    @include for-tablet-only {
        width: 680px;
        margin: 0px auto 44px;
    }

    @include for-mobile-only {
        font-size: 18px;
        line-height: 22px;
        width: 358px;
        margin: 0px auto 43px;
    }
    @include for-small-mobile {
        width: 288px;
    }
}

.barb__info {
    font-family: 'PT Serif Caption', serif;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.03em;

    margin-bottom: 4px;

    @include for-desktop {
        //styleName: Desktop/Heading/H4;
        font-size: 26px;
        line-height: 34px;
    }
    @include for-laptop-only {
        font-size: 24px;
        line-height: 32px;
    }
    @include for-tablet-only {
        font-size: 28px;
        line-height: 37px;

    }
    @include for-mobile-only {
        font-size: 20px;
        line-height: 27px;
    }
}

.barb__info--accent {
    color: var(--accent-color);
}

.barbe__expr {
    font-family: 'PT Serif Caption', serif;
    color: var(--accent-color);
    text-transform: uppercase;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0.03em;

    @include for-desktop {
        font-size: 22px;
        line-height: 29px;
    }
    @include for-laptop-only {
        font-size: 19px;
        line-height: 25px;
    }
    @include for-tablet-only {
        // font-size: 28px;
        font-size: 20px;
        line-height: 37px;
    }
    @include for-mobile-only {
        font-size: 18px;
        line-height: 27px;
    }
}



// КАРТКА БАБАРА І СЛАЙДЕР
.barbes-container {
    @include for-mobile-and-tablet {
        position: relative;
    }
    @include for-small-mobile {
        min-width: 288px;
    }
}
.slider {
    
    @include for-mobile-and-tablet {
            width: 680px;
                height: auto;
                overflow: hidden;
                margin: 20px auto;
                overflow: hidden;
                position: relative;
        }
    @include for-mobile-only {
            width: 359px;
                height: auto;
                overflow: hidden;
                margin: 20px auto;
                overflow: hidden;
        }

    @include for-small-mobile {
        width: 288px;
    }
}

.slider-line {

    @include for-laptop-and-desktop{
        display: flex;
        justify-content: center;
        text-align: center;
        max-width: 846px;
        margin: 0px auto;
    }
    @include for-mobile-and-tablet {
            // width: 1024px;
                display: flex;
                position: relative;
                left: 0;
                transition: all ease 1s;
    }
    
}

.barbes-list__item {
    @include for-laptop-and-desktop {
        display: flex;
        flex-direction: column;
        width: 412px;
        
            &:not(:last-child) {
                margin-right: 24px;
            }
    }
    @include for-laptop-only {
        width: 4357px;
    }
    @include for-tablet-only {
        width: 680px;
        height: auto;
        align-items: center;
        text-align: center;
    }
    @include for-mobile-only {
        width: 359px;
        height: auto;
        align-items: center;
        text-align: center;
    }
    @include for-small-mobile {
        width: 288px;
    }
    
}

// ФОТО З КНОПКОЮ
.barb__card {
    position: relative;
    margin-bottom: 20px;
}
.barb__foto {
    border-radius: 20%;
    @include for-desktop {
        width: 410px;
        height: 427px;
    }
    @include for-laptop-only{
        width: 357px;
        height: 400px;
    }
    @include for-tablet-only {
        width: 446px;
        height: 466px;
    }
    @include for-mobile-only {
        width: 282px;
        height: 300px;
    }
}

.barb__btn {
    font-family: 'PT Serif Caption', serif;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    position: absolute;
    display: block;
    background: rgba(0, 0, 0, 0);
        border: 2px solid #FFFFFF;

    @include for-laptop-and-desktop{
    padding: 13px;
        max-width: min-content;
        padding: 20px 42px;
        color: var(--text-color);
        font-weight: 500;
        font-size: 21px;
    
        bottom: 1%;
        left: 20%;
    }
    @include for-laptop-only {
        left: 17%;
    }
    // @include for-mobile-and-tablet{
    //     display: none;
    // }

    @include for-mobile-and-tablet{
        font-weight: 400;
        color: var(--white-color);
        background-color: var(--background-btn);
    }
    @include for-tablet-only{
        font-size: 24px;
        line-height: 32px;
        padding: 24px 30px;

        bottom: 10px;
        left: 217px;

    }
    @include for-mobile-only {
        font-size: 18px;
        line-height: 24px;
        padding: 18px 30px;
            
        bottom: 10px;
        left: 22%;
    }
    @include for-small-mobile{
        left: 15%;
    }
}


// КНОПКИ ДЛЯ СЛАЙДЕРА
.slider-prev,
.slider-next {
    @include for-laptop-and-desktop {
        display: none;
    }
    @include for-mobile-and-tablet{
        position: absolute;
        color: var(--text-color);
        background: var(--background-btn); 
        border-radius: 12px;
    }
    @include for-tablet-only{
        width: 56px;
        height: 56px;
    }
    @include for-mobile-only{
        width: 44px;
        height: 44px;
    }
    padding: 0px 10px;
    border: none;
    cursor: pointer;
}

.slider-prev {
    @include for-mobile-and-tablet {
        top: 0px;
        left: 0px;       
    }
}
.slider-next {
    @include for-mobile-and-tablet {
        top: 0px;
        right: 0px;       
    }
}
.slider-btn {
    stroke: var(--white-color);
    @include for-tablet-only {
        width: 36px;
        height: 36px;
    }
    @include for-mobile-only {
        width: 24px;
        height: 24px;
    }
    &:hover,
    &:focus,
    &:active {
        stroke: var(--accent-color);
     }
}

//АКТИВНА КАРТКА
  .barb-action {
    @include for-laptop-and-desktop {
    position: absolute;
      top: 0%;
      left: 0%;
      border-radius: 20%;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
    @include for-mobile-and-tablet {
        display: none;
    }
  }

  .barb__card:hover .barb__btn {
    color: var(--text-color);
    border: 2px solid var(--white-color);
    cursor: pointer;
    background: linear-gradient(0deg, #292929, #292929),
            linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }

  .barb__card:hover .barb-action {
      background: rgba(0, 0, 0, 0);
  }

  .barb__card:focus .barb__btn {
      color: var(--text-color);
      background: linear-gradient(0deg, #292929, #292929),
            linear-gradient(0deg, #FFFFFF, #FFFFFF);
    //   border: 2px solid var(--accent-color);
      cursor: pointer;
  }

  .barb__card:focus .barb-action {
      background: rgba(0, 0, 0, 0);
      
  }



// Elipse
.element-list {
    @include for-laptop-and-desktop {
        display: none;
    }
    @include for-tablet-only{
        margin-top: 20px;
    }
    @include for-mobile-only{
        margin-bottom: 50px;
        // margin-top: -20px;
    }
     @include for-small-mobile{
        margin-top: -20px;
    }
    display: flex;
    justify-content: center;
}
.element {
    fill: #686868;
}
  .current {
      fill:#AD9445;
  }

// Background element
  .elem-barb {
    @include for-laptop-and-desktop {
        width: 350px;
        height: 364px;
        display: block;
        position: absolute;
        top: -150px;
        left: -15px;
    }
    @include for-mobile-and-tablet {
        display: none;
    }
  }
  .elem-barb__svg {
    @include for-laptop-and-desktop {
        width: 350px;
        height: 364px;
        fill: var(--element-color);
    }
  }

// Stars
  .stars-list {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    @include for-tablet-only{
        // margin-bottom: 18px;
    }
  }
  .stars-list__item {
    width: 28px;
    height: 28px;

    &:not(:last-child){
        margin-right: 16px;
    }
  }
  .stars-icon {
    stroke: #D9B946;
    fill: #D9B946;
  }