
.menu-container.is-open {
    transform: translateX(0);
}
// .icon__close {
//     display: none;
// }

.is-open .icon__close {
    display: block;
}

.is-open .icon__menu {
    display: none;
}


.menu-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    // height: 100%;
    overflow-y: scroll;
    z-index: 2;

    padding-top: 36px;
    padding-bottom: 48px;
    // padding-left: 40px;

    &.is-open {
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        // width: 306px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: var(--background-color);

        // @include for-mobile-only {
        //     width: 225px;
        // }


    }
}

.js-close-menu {
    width: 44px;
    height: 44px;
    padding: 4px;
    background-color: #1fe0;
    border: none;
    /* position: absolute; */
    margin: 0px 16px 50px auto;
    @include for-tablet-only {
        margin: 0px 32px 50px auto;
    }
}

.menu-info {
    background-color: #3F3F3F;
        display: flex;
            padding: 10px 0px;
            flex-direction: column;
            align-items: center;
            margin-bottom: 94px;
        @include for-mobile-only {
            margin-bottom: 44px;
        }
}
 .menu-info__text {

    font-family: 'PT Serif Caption', serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-left: 12px;

    @include for-mobile-only{
        font-size: 16px;
        line-height: 21px;
    }
 }

 .menu-info__item {
    display: flex;
    justify-content: flex-start;

    @include for-tablet-only {
        min-width: 216px;
    }
    @include for-mobile-only {
        min-width: 195px;
    }
    &:not(:last-child){
        margin-bottom: 7px;
    }
 }
.menu-info__link{
    text-decoration: none;
    display: flex;
    color: var(--text-color);
}
 .menu-nav {
        display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 42px;
 }

 .menu-nav__item {
    &:not(:last-child) {
            margin-bottom: 42px;
        }
 }
 .menu-nav__link {
    font-family: 'PT Serif Caption', serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.03em;
    text-decoration-line: none;
    text-transform: uppercase;
    color: var(--text-color);

    @include for-mobile-only {
        font-size: 20px;
        line-height: 27px;
    }
    &:hover,
     &:focus,
     &:active {
         color: var(--accent-color);
     }
 }

 .menu-nav__btn {
    font-family: 'PT Serif Caption', serif;
        display: block;
        padding: 20px 20px;
        margin: 0px auto 40px auto;
        width: max-content;
    
        color: var(--text-color);
        background: linear-gradient(0deg, #292929, #292929), linear-gradient(0deg, #fff, #fff);
        // background: rgba(0, 0, 0, 0);
        border: 2px solid #FFFFFF;
    
        text-transform: uppercase;
        text-decoration-line: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.05em;
        text-align: center;
    
    
        &:hover,
        &:focus,
        &:active {
            color: var(--accent-color);
            background: rgba(0, 0, 0, 0);
            border: 2px solid var(--accent-color);
            cursor: pointer;

            color: var(--text-color);
            border: 2px solid var(--white-color);
            background: linear-gradient(0deg, #292929, #292929),
                linear-gradient(0deg, #FFFFFF, #FFFFFF);
        }
    @include for-mobile-only {
        font-size: 18px;
        line-height: 24px;
    }
 }

.menu-svg {
     display: flex;
     flex-direction: column;
     align-items: center;
     margin-bottom: 60px;
 }

 
 .menu-logo{
    @include for-tablet-only {
        width: 251px;
        height: 77px;
    }
    @include for-mobile-only {
        width: 186px;
        height: 56px;
    }

    fill: var(--text-color);
      &:hover,
      &:focus,
      &:active {
          fill: var(--accent-color);
    }
 }
 .menu-insta {
    padding: 12px;
    fill: var(--text-color);
        &:hover,
        &:focus,
        &:active {
            fill: var(--accent-color);
        }
 }
//   .menu-svg__item {
//       &:not(:last-child) {
//           margin-bottom: 12px;
//       }
//   }

  .close-menu__svg {
      fill: var(--white-color);
      &:hover,
      &:focus,
      &:active {
          fill: var(--accent-color);
      }
  }